import pkg from '../../../package.json';

export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true,
  COMPANY_NAME: "Off Price",
  COMPANY_ABB: "op",
  SHOW_COUNTRY_SELECT: false,

  ALLOW_CHANGE_EMAIL_AND_PHONE: false,
  REGISTRATION_HOME_IF_DIFFERENT: false,
  CONTACT_INFO: [
    {
      infoIcon: "info-circle",
      infoValue: "OFF PRICE SHOWROOM",
      infoUrl: null
    },
    {
      infoIcon: "environment",
      infoValue: "Via Giancarlo Sismondi, 48, 20133 Milano MI, Italy",
      infoUrl: null
    },
    {
      infoIcon: "phone",
      infoValue: "+39 351 953 7466",
      infoUrl: null
    },
    {
      infoIcon: "mail",
      infoValue: "info@offpricesale.com",
      infoUrl: null
    }
  ],
  COUNTRY_SELECT: [
    {
      COUNTRY_NAME: "Italia",
      COUNTRY_FLAG: "it",
      COUNTRY_BASE_PATH: 'https://api-op.event-sales.com',
      COUNTRY_MEDIA_PATH: "https://media-op.event-sales.com",
      COUNTRY_DEFAULT_LANGUAGE: "it-it"
    }
  ],
  DEFAULT_PHONE_PREFIX: "+39",
  MESSAGE_DURATION: 2500,
  NEW_TRAN_SYMBOL: "",
  EDIT_TRAN_SYMBOL: "",
  EDIT_TRAN_AFFIX: "booking_",
  ERROR_NOTIFICATION_DURATION: 0,
  LOADER_DELAY: 200,
  SAFARI_COLOR: "#ff0000",
  HTML_THEME_COLOR: "#ffffff",
  HTML_TITLE_COLOR: "#ffc40d",
  REGISTRATION_FULL_ADDRESS: false,
  USE_IUBENDA: false,
  IUBENDA_COOKIE_POLICY: '',
  IUBENDA_CONS: '',
  IUBENDA_DEFAULT_HTML: '',
  SHOW_PUBLIC_EVENT: false,
  SHOW_EVENTS_SALE_NAME: true,
  SHOW_CHEK_IN_INFO: false,
  SHOW_ALL_PRIVATE_SALE_CONTACTS: false,
  SHOW_MEMBERSHIPS: false,
  REGISTER_TYPE: "full",
  DEFAULT_MENU_LEVEL: 1000,
  SOCIAL_IG: true,
  SOCIAL_FB: true,
  SOCIAL_LK: true,
  SOCIAL_WA: false
};
